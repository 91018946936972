<template>
  <v-card class="pa-4 admin-report-card">
    <v-row class="report-wrap">
      <v-col
        md="3"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <p class="text-h6">
          Cashiers Report
        </p>
      </v-col>
      <v-col
        md="3"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="warehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          outlined
          :disabled="fetching" />
      </v-col>
      <v-col
        md="4"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <date-picker
          v-model="dateSelected"
          :disabled="fetching" />
      </v-col>
      <v-col
        cols="2"
        class="d-flex justify-end">
        <v-btn
          class="mt-2"
          :loading="fetching"
          @click="fetchReport()">
          Go
        </v-btn>
        <v-btn
          class="mt-2 ml-2"
          color="success"
          :loading="fetching"
          @click="exportCsv()">
          Export CSV
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="fetching"
          :items-per-page="-1"
          dense
          hide-default-footer>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NewReportProvider from '@/resources/NewReportProvider'
import { mapGetters } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import DatePicker from '../components/DatesPicker.vue'

const NewReportService = new NewReportProvider()

export default {
  components: {
    DatePicker,
    WarehouseSelect
  },
  data () {
    return {
      fetching: false,
      warehouseSelected: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Email', value: 'email' },
        { text: 'Total', value: 'sumOrderNet', align: 'right' }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()],
      items: [],
      total: 0
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return [
        {
          id: null,
          name: 'All',
          code: 'all'
        },
        ...this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
      ]
    }
  },
  watch: {
    dateSelected: {
      handler () {
        this.fetchReport()
      },
      deep: true
    },
    warehouseSelected () {
      this.fetchReport()
    }
  },
  mounted () {
    this.fetchReport()
  },
  methods: {
    exportCsv () {
      const startDate = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const endDate = this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const options = {
          filename: `CASHIER_${startDate}_${endDate}`,
          showLabels: true,
          useKeysAsHeaders: true
        }
      const csvExporter = new ExportToCsv(options)

      const data = this.items.map((item) => {
        const result = {}
        this.headers.forEach((h) => {
          result[h.text] = item[h.value]
        })

        return result
      })
      csvExporter.generateCsv(data)
    },
    resetData () {
      this.items = []
      this.total = 0
    },
    async fetchReport () {
      this.resetData()
      try {
        this.fetching = true
        const { data } = await NewReportService.getCashierReport({
          warehouse: this.warehouseSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.items = data.map((d) => ({
          ...d,
          sumOrderNet: parseFloat(d.sumOrderNet).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }))
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    }
  }
}
</script>
